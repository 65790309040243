import React from "react";

// Customizable Area Start
import {
  Button,
  Container,
  FormControl,
  FormGroup,
  MenuItem,
  TextField,
  Typography,
  TextFieldProps,
} from "@mui/material";

import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
// Customizable Area End
import FedexintegrationController, {
  configJSON,
  Props,
  S,
} from "./FedexintegrationController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: { fontWeight: 500 },
    subtitle1: { margin: "20px 0px" },
  },
});
// Customizable Area End

export default class Fedexintegration extends FedexintegrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderControl(
    label: string,
    valueKey: keyof S,
    type?: React.InputHTMLAttributes<unknown>["type"],
    rest?: Partial<TextFieldProps>
  ) {
    return (
      <FormControl margin={"normal"}>
        <TextField
          data-test-id={label}
          type={type}
          required
          label={label}
          variant="standard"
          defaultValue={this.state[valueKey]}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            this.setTextInputValue(valueKey, event.target.value);
          }}
          InputLabelProps={{ shrink: true }}
          {...rest}
        />
      </FormControl>
    );
  }
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth={"md"}>
            <FormGroup style={webStyle.innerContainer}>
              {this.renderControl(
                configJSON.loadDescriptionText,
                "load_description"
              )}

              <FormControl margin={"normal"}>
                <TextField
                  data-test-id={configJSON.packagingTypeText}
                  select
                  label={configJSON.packagingTypeText}
                  value={this.state.item_type}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setTextInputValue("item_type", event.target.value);
                  }}
                >
                  {this.state.item_type_list.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>

              <Typography variant="h5" style={webStyle.title}>
                {configJSON.deliveryDetailsText}
              </Typography>

              {this.renderControl(configJSON.nameText, "name")}
              {this.renderControl(configJSON.phoneText, "phone", "phone")}
              {this.renderControl(configJSON.emailText, "email", "email")}
              {this.renderControl(configJSON.addressText, "address")}
              {this.renderControl(configJSON.cityText, "city")}
              {this.renderControl(configJSON.countryText, "country")}
              {this.renderControl(
                configJSON.beginingAtText,
                "delivery_begin_at",
                "date",
                { inputProps: { min: this.minDate, max: this.maxDate } }
              )}
              {this.renderControl(
                configJSON.endAtText,
                "delivery_end_at",
                "date",
                { inputProps: { min: this.minDate, max: this.maxDate } }
              )}
              {this.renderControl(configJSON.instructionsText, "instructions")}

              <Typography variant="h5" style={webStyle.title}>
                {configJSON.pickupDetailsText}
              </Typography>

              {this.renderControl(configJSON.nameText, "p_name")}
              {this.renderControl(configJSON.phoneText, "p_phone", "phone")}
              {this.renderControl(configJSON.emailText, "p_email", "email")}
              {this.renderControl(configJSON.addressText, "p_address")}
              {this.renderControl(configJSON.cityText, "p_city")}
              {this.renderControl(configJSON.countryText, "p_country")}
              {this.renderControl(
                configJSON.beginingAtText,
                "pickup_begin_at",
                "date",
                { inputProps: { min: this.minDate, max: this.maxDate } }
              )}
              {this.renderControl(
                configJSON.endAtText,
                "pickup_end_at",
                "date",
                { inputProps: { min: this.minDate, max: this.maxDate } }
              )}
              {this.renderControl(
                configJSON.instructionsText,
                'p_instructions'
              )}
              {this.renderControl(configJSON.amountText, "amount", "number")}
              {this.renderControl(configJSON.currencyText, "currency")}

              <Typography variant="h5" style={webStyle.title}>
                {configJSON.deliveryLocationDetailsText}
              </Typography>

              <FormControl margin={"normal"}>
                <TextField
                  data-test-id={configJSON.longitudeText}
                  required
                  label={configJSON.longitudeText}
                  variant="standard"
                  type="number"
                  defaultValue={this.state.longitude}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setTextInputValue("longitude", event.target.value);
                  }}
                  onBlur={() => this.checkDecimal("longitude", "dlongerr")}
                  error={this.state.dlongerr !== ""}
                  helperText={this.state.dlongerr}
                />
              </FormControl>
              <FormControl margin={"normal"}>
                <TextField
                  data-test-id={configJSON.latitudeText}
                  required
                  label={configJSON.latitudeText}
                  variant="standard"
                  type="number"
                  defaultValue={this.state.latitude}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setTextInputValue("latitude", event.target.value);
                  }}
                  onBlur={() => this.checkDecimal("latitude", "dlaterr")}
                  error={this.state.dlaterr !== ""}
                  helperText={this.state.dlaterr}
                />
              </FormControl>

              <Typography variant="h5" style={webStyle.title}>
                {configJSON.pickupLocationDetailsText}
              </Typography>
              <FormControl margin={"normal"}>
                <TextField
                  data-test-id={configJSON.longitudeText}
                  required
                  label={configJSON.longitudeText}
                  variant="standard"
                  type="number"
                  defaultValue={this.state.p_longitude}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setTextInputValue("p_longitude", event.target.value);
                  }}
                  onBlur={() => this.checkDecimal("p_longitude", "plongerr")}
                  error={this.state.plongerr !== ""}
                  helperText={this.state.plongerr}
                />
              </FormControl>
              <FormControl margin={"normal"}>
                <TextField
                  data-test-id={configJSON.latitudeText}
                  required
                  label={configJSON.latitudeText}
                  variant="standard"
                  type="number"
                  defaultValue={this.state.p_latitude}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setTextInputValue("p_latitude", event.target.value);
                  }}
                  onBlur={() => this.checkDecimal("p_latitude", "platerr")}
                  error={this.state.platerr !== ""}
                  helperText={this.state.platerr}
                />
              </FormControl>

              <Typography variant="h5" style={webStyle.title}>
                {configJSON.itemDetailsText}
              </Typography>

              {this.renderControl(configJSON.lengthText, "length", "number")}
              {this.renderControl(configJSON.widthText, "width", "number")}
              {this.renderControl(configJSON.heightText, "height", "number")}
              {this.renderControl(
                configJSON.quantityText,
                'quantity',
                'number'
              )}
              {this.renderControl(configJSON.weightText, "weight", "number")}

              <Button
                data-test-id="submitButton"
                color="primary"
                variant="contained"
                component="span"
                style={webStyle.submitButton}
                disabled={this.state.loadingShipment}
                onClick={() => this.createShipment()}
              >
                {configJSON.submitText}
              </Button>
            </FormGroup>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const webStyle = {
  innerContainer: {
    margin: "2rem 0",
  },
  title: {
    margin: "1rem 0 0 0",
  },
  submitButton: {
    margin: "1rem 0 0",
  },
};

// Customizable Area End
