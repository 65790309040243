Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.fedexApiContentType = "application/json";
exports.fedexApiMethodType = "POST";
exports.fedexApiMethodTypeGet = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "fedexintegration";
exports.labelBodyText = "fedexintegration Body";
exports.crateShipmentError = "Please Fill the mandatory fields";
exports.crateShipmentMailError = "Invalid Email Id";
exports.errorCreateShipmentError = "*";
exports.createShippmentAPiEndPoint = "fedex_integration/shipments";
exports.fedexGetShipmentDetailsEndpoint = "fedex_integration/shipments/";

exports.dateFormat = "YYYY-MM-DD  HH:mm:ss";

exports.btnExampleTitle = "CLICK ME";

exports.messageReceivedText = "Message Received";
exports.unexpectedErrorText = "Something went wrong!";
exports.serverErrorText = "Internal Server Error";
exports.shipmentDetailsErrorText = "Shipment Details Not Found!";
exports.decimalErrorText = "Please enter decimal value e.g 12.345345";
exports.deliveryErrorText = "Delivery arrival begin time should be before delivery arrival end window";
exports.pickupErrorText = "Pickup arrival begin time should be before pickup arrival end window";
exports.submitText = "Submit";
exports.loadDescriptionText = "Load Description";
exports.packagingTypeText = "Packaging Type";
exports.selectTypeText = "Select Type";
exports.deliveryDetailsText = "Delivery Details";
exports.nameText = "Name";
exports.phoneText = "Phone";
exports.emailText = "Email";
exports.addressText = "Address";
exports.cityText = "City";
exports.countryText = "Country";
exports.instructionsText = "Instructions";
exports.pickupDetailsText = "Pickup Details";
exports.amountText = "Amount";
exports.currencyText = "Currency";
exports.deliveryLocationDetailsText = "Delivery Location Details";
exports.longitudeText = "Longitude";
exports.latitudeText = "Latitude";
exports.pickupLocationDetailsText = "Pickup Location Details";
exports.itemDetailsText = "Item Details";
exports.lengthText = "Length";
exports.widthText = "Width";
exports.heightText = "Height";
exports.quantityText = "Quantity";
exports.weightText = "Weight";
exports.beginingAtText = "Beginning At";
exports.endAtText = "End At";

exports.enterTrackingIdText = "Enter Tracking Id";
exports.goText = "Go";
exports.orderStatusText = "Order Status";
exports.shippingAddressText = "Shipping Address";
exports.phoneNumberText = "Phone Number";
exports.errorTitleText = "Error";
exports.confirmText = "Confirm";
exports.cancelText = "Cancel";
exports.selectDate = "Select Date";

// Customizable Area End
