import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config.js");

// Customizable Area Start
import moment from "moment";
import { Information, Shipment } from "./types";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token?: string;
  trackId?: string;
  loadingTrack?: boolean;
  shipmentDetail?: Shipment;
  arrivalWindow?: Information["arrival_window"];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FedexintegrationOrderTrackingController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiShipmentDetailCallId?: string;

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      loadingTrack: false
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    this.getToken();
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog(configJSON.messageReceivedText, message);

      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiShipmentDetailCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const error = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (error) {
        this.parseApiCatchErrorResponse(error);
      } else {
        const response = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (response && response.error) {
          this.parseApiCatchErrorResponse(response.error);
        } else {
          const shipment = response.shipment.data;
          const shipments = shipment.attributes.shipments;

          this.setState({
            loadingTrack: false,
            shipmentDetail: shipments[0],
            arrivalWindow: shipments[0].delivery.arrivalWindow
          });
        }
      }
    }
    // Customizable Area End
  }

  loadShipmentDetails(authKey: string, shipmentId: string): boolean {
    this.setState({ loadingTrack: true });

    const header = {
      "Content-Type": configJSON.fedexApiContentType,
      token: authKey
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiShipmentDetailCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fedexGetShipmentDetailsEndpoint + shipmentId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.fedexApiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  setTextInputValue = (attribute: keyof S, value: string) => {
    this.setState({ [attribute]: value });
  };

  getData(attribute: "begin" | "end") {
    return moment(this.state.arrivalWindow?.[attribute]).format(
      "ddd, Do MMMM  YY"
    );
  }

  // Customizable Area End
}
