import React from 'react';
// Customizable Area Start
import {
  Button,
  Container,
  FormControl,
  FormGroup,
  TextField,
  Typography,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: { fontWeight: 500 },
    subtitle1: { margin: '20px 0px' },
  },
});
// Customizable Area End

import FedexintegrationOrderTrackingController, {
  Props,
  configJSON,
} from './FedexintegrationOrderTrackingController';

export default class FedexintegrationOrderTracking extends FedexintegrationOrderTrackingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <>
        {/* Customizable Area Start */}
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Container maxWidth={'md'}>
              <FormGroup style={webStyle.innerContainer}>
                <FormControl margin={'normal'}>
                  <TextField
                    data-test-id={configJSON.enterTrackingIdText}
                    label={configJSON.enterTrackingIdText}
                    variant="standard"
                    defaultValue={this.state.trackId}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      this.setTextInputValue('trackId', event.target.value);
                    }}
                  />
                  <Button
                    data-test-id={configJSON.goText}
                    color="primary"
                    variant="contained"
                    component="span"
                    style={webStyle.submitButton}
                    disabled={
                      !this.state.token ||
                      !this.state.trackId ||
                      this.state.loadingTrack
                    }
                    onClick={() => {
                      this.loadShipmentDetails(
                        this.state.token!,
                        this.state.trackId!
                      );
                    }}
                  >
                    {configJSON.goText}
                  </Button>

                  <Divider />

                  <div style={webStyle.loader}>
                    {this.state.loadingTrack ? <CircularProgress /> : null}
                  </div>

                  {this.state.shipmentDetail ? (
                    <>
                      <Typography variant="h5" style={webStyle.title}>
                        {configJSON.orderStatusText}
                      </Typography>

                      <List>
                        <ListItem>
                          <ListItemText primary={this.getData('begin')} />
                          <ListItemText primary={this.getData('end')} />
                        </ListItem>
                      </List>

                      <Typography variant="h5" style={webStyle.title}>
                        {configJSON.shippingAddressText}
                      </Typography>
                      <List>
                        {this.state.shipmentDetail && (
                          <ListItem>
                            <ListItemText
                              primary={
                                this.state.shipmentDetail.delivery
                                  .address as string
                              }
                            />
                            <ListItemText
                              primary={`${configJSON.phoneNumberText}: ${this.state.shipmentDetail.delivery.phone}`}
                            />
                          </ListItem>
                        )}
                      </List>
                    </>
                  ) : (
                    <></>
                  )}
                </FormControl>
              </FormGroup>
            </Container>
          </ThemeProvider>
        </StyledEngineProvider>
        {/* Customizable Area End */}
      </>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const webStyle = {
  innerContainer: {
    margin: '2rem 0',
  },
  title: {
    margin: '1rem 0 0 0',
  },
  submitButton: {
    margin: '1rem 0 0',
  },
  loader: {
    marginTop: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
// Customizable Area End
