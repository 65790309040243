import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start

  mode: "track" | "create";

  address: string;
  city: string;
  length: string;
  width: string;
  height: string;
  weight: string;
  country: string;
  email: string;
  name: string;
  phone: string;
  instructions: string;
  amount: string;
  currency: string;
  token: string;
  load_description: string;
  latitude: string;
  longitude: string;
  quantity: string;

  p_latitude: string;
  p_longitude: string;
  p_address: string;
  p_city: string;
  p_country: string;
  p_name: string;
  p_phone: string;
  p_email: string;
  p_instructions: string;
  isSubmit: boolean;
  stackable: boolean;
  item_type: string;
  loading: boolean;
  trackId: string;
  loadingTrack: boolean;
  loadingShipment: boolean;
  isShipmentCreated: boolean;
  delivery_begin_at: string;
  delivery_end_at: string;
  delivery_end_time: string;
  pickup_begin_at: string;
  pickup_end_at: string;
  item_type_list: { value: string }[];
  deliveryDateError: string;
  pickupDateError: string;
  params: string;
  dlongerr: string;
  dlaterr: string;
  plongerr: string;
  platerr: string;

  [key: string]: unknown;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FedexintegrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  fedexApiCallId?: string;
  apiShipmentDetailCallId?: string;

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    // Customizable Area Start
    this.state = {
      mode: "create",
      load_description: "",
      latitude: "",
      longitude: "",
      address: "",
      city: "",
      length: "",
      width: "",
      height: "",
      weight: "",
      country: "",
      email: "",
      name: "",
      phone: "",
      instructions: "",
      amount: "",
      currency: "",
      token: "",
      quantity: "",
      item_type: "",
      item_type_list: [
        {
          value: "PALLET",
        },
        {
          value: "BOX",
        },
        {
          value: "OTHER",
        },
      ],
      stackable: true,

      p_latitude: "",
      p_longitude: "",
      p_address: "",
      p_city: "",
      p_country: "",
      p_email: "",
      p_name: "",
      p_phone: "",
      p_instructions: "",
      isSubmit: false,
      loading: false,
      loadingTrack: false,
      loadingShipment: false,
      trackId: "",
      isShipmentCreated: false,
      delivery_begin_at: "",
      delivery_end_at: "",
      delivery_end_time: "",
      pickup_begin_at: "",
      pickup_end_at: "",
      deliveryDateError: "",
      pickupDateError: "",
      params: "",
      dlongerr: "",
      dlaterr: "",
      plongerr: "",
      platerr: "",
    };
    // Customizable Area End

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog(configJSON.messageReceivedText, message);

      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.fedexApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({ loadingShipment: false });

      const error = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (error) {
        this.parseApiCatchErrorResponse(error);
      } else {
        const response = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (response && response.error) {
          this.parseApiCatchErrorResponse(response.error);
        } else {
          this.setState({
            isShipmentCreated: true,
            isSubmit: true,
            trackId: response.shipment.data.id,
          });
        }
      }
    }
    // Customizable Area End
  }

  createShipment(): boolean {
    if (
      !this.validateEmail(this.state.email) ||
      !this.validateEmail(this.state.p_email)
    ) {
      this.showAlert(
        configJSON.crateShipmentMailError,
        configJSON.errorCreateShipmentError
      );
      return false;
    }

    if (
      this.isEmpty(this.state.name) ||
      this.isEmpty(this.state.phone) ||
      this.isEmpty(this.state.delivery_begin_at) ||
      this.isEmpty(this.state.delivery_end_at) ||
      this.isEmpty(this.state.pickup_begin_at) ||
      this.isEmpty(this.state.pickup_end_at) ||
      this.isEmpty(this.state.address) ||
      this.isEmpty(this.state.city) ||
      this.isEmpty(this.state.country) ||
      this.isEmpty(this.state.p_phone) ||
      this.isEmpty(this.state.p_address) ||
      this.isEmpty(this.state.p_city) ||
      this.isEmpty(this.state.p_country) ||
      this.isEmpty(this.state.p_latitude) ||
      this.isEmpty(this.state.p_longitude) ||
      this.isEmpty(this.state.height) ||
      this.isEmpty(this.state.width) ||
      this.isEmpty(this.state.length) ||
      this.isEmpty(this.state.amount) ||
      this.isEmpty(this.state.currency) ||
      this.isEmpty(this.state.quantity) ||
      this.isEmpty(this.state.weight)
    ) {
      this.showAlert(
        configJSON.crateShipmentError,
        configJSON.errorCreateShipmentError
      );
      return false;
    }

    this.setState({ loadingShipment: true });

    const header = {
      "Content-Type": configJSON.fedexApiContentType,
      token: this.state.token,
    };

    const attrs = {
      data: {
        attributes: {
          shipments_attributes: [
            {
              full_truck: false,
              load_description: this.state.load_description,
              cod_value_attributes: {
                amount: parseFloat(this.state.amount),
                currency: this.state.currency,
              },
              shipment_value_attributes: {
                amount: parseFloat(this.state.amount),
                currency: this.state.currency,
              },
              delivery_attributes: {
                address: this.state.address,
                city: this.state.city,
                country: this.state.country,
                email: this.state.email,
                name: this.state.name,
                phone: this.state.phone,
                instructions: this.state.instructions,
                arrival_window_attributes: {
                  begin_at: this.state.delivery_begin_at + " UTC",
                  end_at: this.state.delivery_end_at + " UTC",
                },
                coordinate_attributes: {
                  latitude: this.state.latitude,
                  longitude: this.state.longitude,
                },
              },
              pickup_attributes: {
                address: this.state.address,
                city: this.state.p_city,
                country: this.state.p_country,
                email: this.state.p_email,
                name: this.state.p_name,
                phone: this.state.p_phone,
                instructions: this.state.p_instructions,
                arrival_window_attributes: {
                  begin_at: this.state.pickup_begin_at + " UTC",
                  end_at: this.state.pickup_end_at + " UTC",
                },
                coordinate_attributes: {
                  latitude: this.state.p_latitude,
                  longitude: this.state.p_longitude,
                },
              },
              items_attributes: [
                {
                  weight: parseFloat(this.state.weight),
                  quantity: parseInt(this.state.quantity, 10),
                  stackable: this.state.stackable,
                  item_type: this.state.item_type,
                  dimension_attributes: {
                    height: parseInt(this.state.height, 10),
                    length: parseInt(this.state.length, 10),
                    width: parseInt(this.state.width, 10),
                  },
                },
              ],
            },
          ],
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fedexApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createShippmentAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.fedexApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  isEmpty(value: string) {
    return value === null || value.length === 0;
  }

  validateEmail(mail: string) {
    const regexp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(String(mail).toLowerCase());
  }

  setTextInputValue = (attribute: keyof S, value: string) => {
    this.setState({ [attribute]: value });
  };

  checkDecimal(valueKey: keyof S, errorKey: keyof S) {
    let checkLong = parseFloat(this.state[valueKey] as string);
    if (Number.isInteger(checkLong)) {
      this.setState({
        [errorKey]: configJSON.decimalErrorText,
      });
    } else {
      this.setState({
        [errorKey]: "",
      });
    }
  }

  setArrivalTime(
    endDate: string,
    beginKey: keyof S,
    endKey: keyof S,
    errorKey: keyof S
  ) {
    const endDateUTC = moment.utc(
      endDate.substr(0, endDate.indexOf(" ")) +
        " " +
        endDate.substr(endDate.indexOf(" "), endDate.length),
      configJSON.dateFormat
    );
    if (
      endDateUTC.isAfter(
        (this.state[beginKey] as string).replace(/\s/g, "T") + "Z"
      )
    ) {
      this.setState({ [endKey]: endDate, [errorKey]: "" });
    } else {
      this.setState({ [errorKey]: configJSON.deliveryErrorText });
    }
  }

  get minDate() {
    return `${moment().year()}-01-01`;
  }

  get maxDate() {
    return `${moment().year() + 1}-01-01`;
  }

  // Customizable Area End
}
